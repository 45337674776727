<template>
  <v-card v-on="$listeners" height="175" width="175" class="pa-2 ma-4 d-flex flex-column justify-space-between" outlined :style="`border-color: ${ tipo.color }`"  >
    <h4 class="text-h6 text-center pa-2">{{ tipo.nombreTipo }}</h4>
    <v-icon :color="tipo.color" class="mb-2">{{ tipo.icon }}</v-icon>
  </v-card>
</template>

<script>
export default {
  props: {
    tipo: Object,
  },
};
</script>
